import React from "react";

import { BrowserRouter as Router, Routes, Route } from "react-router-dom";

import { AffiliateLinkForm } from "./components/Form";
import { ViasatLinkForm } from "./components/ViasatForm";
import { UnifiedFieldServicesForm } from "./components/UnifiedFieldServicesForm";
import { UBIFForm } from './components/UBIFForm';
import {ISPPilotForm} from "./components/ISPPilot";

const App = () => {
  return (
    <>
    <Router>
      <section className="section">
        <div className="container">
          <Routes>
            <Route path="/viasat" element={<ViasatLinkForm />} />
            <Route path="/ufs" element={<UnifiedFieldServicesForm />} />  
            <Route path="/ubif" element={<UBIFForm />} />
            <Route path="/isp-pilot" element={<ISPPilotForm />} />
            <Route path="/"element={<AffiliateLinkForm />} />
          </Routes>
        </div>
      </section>
    </Router>
    <section className="footer">
      <div className="container">
        <a href="https://www.asurion.com/privacy-notice/" title="Privacy Notice">Privacy Notice</a>
      </div>
    </section>
    </>
  );
};

export default App;
